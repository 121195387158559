import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import "./layout.css"
// import BottomNav from "./bottom-nav"
import TopNav from "./top-nav"
import styled from "styled-components"
import { media } from "./sky-splash/styles"

const Wrapper = styled.div`
  overflow-x: auto;
  overflow-y: auto;
  flex: 1;
  padding-bottom: 5rem;
  position: relative;
`
const WindowWrapper = styled.div`
  height: 100vh;
  background: linear-gradient(#152a60, #156ac5, #a5d2e4);
  overflow: hidden;
  display: flex;
  h2,
  h3,
  h4 {
    font-family: "Space Grotesk", monospace;
  }
  h4 {
    color: #fff;
  }
`
const FooterWrapper = styled.div`
  max-width: 780px;
  margin: 0 auto;
  ${media.handheld`
    text-align: center;
  `}
`

const Layout = ({ children, location, navPosition = "relative" }) => {
  return (
    <WindowWrapper>
      <Wrapper>
        <TopNav location={location} theme="dark" position={navPosition} />
        <main
          style={{
            maxWidth: 780,
            margin: `24px auto`,
            fontFamily: "Outfit, sans-serif",
          }}
        >
          {children}
        </main>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </Wrapper>
    </WindowWrapper>
  )
}
export const ContentWrapper = styled.div`
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  padding: 36px;
  margin: 20px 0 5px 0;
  border: 1px solid #000;
  ${media.handheld`
  padding: 24px;
  `}
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
