import React from "react"
import styled from "styled-components"

const StyledFooter = styled.div`
  font-family: "Space Grotesk", monospace;
  font-size: 14px;
  font-weight: 600;
`

const Footer = () => {
  return <StyledFooter>All Rights Reserved @ 2022</StyledFooter>
}
export default Footer
