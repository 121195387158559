import React from "react"
import Layout, { ContentWrapper } from "../../../components/layout"
import SEO from "../../../components/seo"
import Header from "../../../components/header"
import { StaticImage } from "gatsby-plugin-image"

const Pikachu = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Pikachu Volleyball" />
      <Header
        siteTitle={
          <>
            {/* <StaticImage
              src="https://github.com/franxyzxyz/pikachu-volleyball/raw/gh-pages/img/pokeball_sm.png"
              layout="fixed"
              width={50}
              style={{ display: "inline-block", verticalAlign: "text-bottom" }}
            /> */}
            Pikachu Volleyball (2015)
          </>
        }
      />
      <ContentWrapper>
        <p>
          The very first javascript mini-game I made. It is a remake of a
          classic Japanese multiplayer game. Players can jump, spike and move to
          hit the pikaball at perfect angle and to gain points
        </p>
        <p>
          Executing animation through coding was the magic point for me on
          Javascript. I wanted to challenge myself on coding motions in physics
          (trajectory and 3rd Law) with this nostalgic game which I used to play
          on a daily basis in secondary school
        </p>
        {/* <StaticImage src="https://github.com/franxyzxyz/pikachu-volleyball/raw/gh-pages/img/classicSS.png" /> */}
        <div>
          Pikachu Volleyball is a classic Japanese multiplayer game that was
          around more than a decade ago at a time when the the world was still
          using floppy disk to store the game and pass around. It was an .exe
          and has only been available on Window OS sadly. This is a remake of
          the game with JavaScript/HTML/CSS in browser environment. Pikachu can
          jump, spike, move around freely.
        </div>
        <div>
          <h2>THE GAME</h2>
          Three orientations are invovled: initialize, game page, restart page
          {/* <StaticImage src="https://github.com/franxyzxyz/pikachu-volleyball/raw/gh-pages/img/%5BSS%5DgameStart.png" />
        <StaticImage src="https://github.com/franxyzxyz/pikachu-volleyball/raw/gh-pages/img/%5BSS%5Djump.jpg" /> */}
          INITALIZE: Player can hover on the key to get info on controls canvas
          GAME: Player can jump with the corresponding control jump up key jump
          RESTART: GAME prompt for restart when maximum point is reached
          gameover
          {/* <StaticImage src="https://github.com/franxyzxyz/pikachu-volleyball/raw/gh-pages/img/%5BSS%5Dgameover.png" /> */}
        </div>
      </ContentWrapper>
    </Layout>
  )
}
export default Pikachu
