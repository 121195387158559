import styled, { css } from "styled-components"

const media = {
  handheld: (...args) => css`
    @media (max-width: 600px) {
      ${css(...args)}
    }
  `,
  tablet: (...args) => css`
    @media (max-width: 769px) {
      ${css(...args)}
    }
  `,
}
export const Header = styled.h1`
  font-family: "Outfit", sans-serif;
  display: block;
  font-size: ${props => props.size || "48px"};
  color: #fff;
  font-family: "Outfit", sans-serif;

  font-weight: 900;
  padding: 0 0 0 0;
  margin: 0;
  margin-top: ${props => props.marginTop || 0};
  ${media.handheld`
font-size: ${props =>
    props.sizeQuery ? `${props.sizeQuery * 0.7}px` : "50px"};
`}
  ${media.tablet`
font-size: ${props =>
    props.sizeQuery ? `${props.sizeQuery * 0.7}px` : "50px"};
`}
-webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #000;
  margin-bottom: 30px;
`
